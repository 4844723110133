'use client';
import { LogOut, UserRound } from 'lucide-react';
import Link from 'next/link';
import { cn } from '@utils';
import { useCallback, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useIsMobile } from '../../hooks/use-mobile';
import { Button } from '../button/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../dialog/dialog';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '../drawer/drawer';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarSeparator,
  SidebarTrigger,
} from '../sidebar/sidebar';
import { AvatarMenu, PopoverAvatarMenu } from '../sidebar/avatar-menu';
import { Topbar, TopbarLeft, TopbarRight } from '../sidebar/topbar';

type LogoutCtaProps = {
  mode: 'dialog' | 'drawer';
  logoutUrl: string;
  logoutWarningText: string;
  logoutConfirmText: string;
  logoutCancelText: string;
  children: React.ReactNode;
};

function LogoutConfirm({
  mode,
  logoutUrl,
  logoutWarningText,
  logoutConfirmText,
  logoutCancelText,
  children,
}: LogoutCtaProps): React.JSX.Element {
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const onClickLogout = useCallback(async (): Promise<void> => {
    setIsLoggingOut(true);
    window.location.replace(logoutUrl);
  }, [logoutUrl]);

  if (mode === 'dialog') {
    return (
      <Dialog>
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent className="flex flex-col items-center justify-center">
          <DialogHeader>
            <DialogDescription />
            <DialogTitle>{logoutWarningText}</DialogTitle>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" className="w-full" variant="secondary">
                {logoutCancelText}
              </Button>
            </DialogClose>
            <Button
              onClick={onClickLogout}
              className="w-full"
              variant="destructive"
              data-testid="confirm-logout"
              loading={isLoggingOut}
            >
              {logoutConfirmText}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }
  if (mode === 'drawer') {
    return (
      <Drawer>
        <DrawerTrigger asChild>{children}</DrawerTrigger>
        <DrawerContent className="w-full">
          <DrawerHeader>
            <DrawerTitle>{logoutWarningText}</DrawerTitle>
          </DrawerHeader>
          <DrawerFooter>
            <DrawerClose asChild>
              <Button className="w-full" type="button" variant="secondary">
                {logoutCancelText}
              </Button>
            </DrawerClose>
            <Button
              className="w-full"
              onClick={onClickLogout}
              variant="destructive"
              data-testid="confirm-logout"
              loading={isLoggingOut}
            >
              {logoutConfirmText}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }
  return <></>;
}

export type AuthenticatedLayoutProps = {
  logo?: React.ReactNode;
  avatarImageUrl?: string;
  avatarFallback: string;
  avatarTitle: string;
  avatarSubTitle?: string;
  profileText?: string;
  profilePath?: string;
  logoutText?: string;
  sidebarContent?: React.ReactNode;
  sidebarFooter?: React.ReactNode;
  showSidebarFooterSeparator?: boolean;
  portalContainer?: HTMLElement;
  className?: string;
  children: React.ReactNode;
} & Partial<LogoutCtaProps>;

export const AuthenticatedLayout = ({
  logo,
  avatarImageUrl,
  avatarFallback,
  avatarTitle,
  avatarSubTitle,
  profileText,
  profilePath,
  logoutText,
  sidebarContent,
  sidebarFooter,
  showSidebarFooterSeparator = true,
  portalContainer,
  className,
  children,
  ...props
}: AuthenticatedLayoutProps): JSX.Element => {
  const pathname = usePathname();
  const isMobile = useIsMobile();

  const AvatarMenuContent = (
    <SidebarMenu className="text-subtext">
      {profilePath && profileText ? (
        <SidebarMenuItem>
          <SidebarMenuButton asChild isActive={profilePath === pathname}>
            <Link href={profilePath}>
              <UserRound />
              <span>{profileText}</span>
            </Link>
          </SidebarMenuButton>
        </SidebarMenuItem>
      ) : null}
      {logoutText && props.logoutUrl && props.logoutWarningText && props.logoutConfirmText && props.logoutCancelText ? (
        <SidebarMenuItem>
          <LogoutConfirm
            mode={isMobile ? 'drawer' : 'dialog'}
            logoutUrl={props.logoutUrl}
            logoutWarningText={props.logoutWarningText}
            logoutConfirmText={props.logoutConfirmText}
            logoutCancelText={props.logoutCancelText}
          >
            <SidebarMenuButton className="text-error-primary" data-testid="logout-button">
              <LogOut />
              <span>{logoutText}</span>
            </SidebarMenuButton>
          </LogoutConfirm>
        </SidebarMenuItem>
      ) : null}
    </SidebarMenu>
  );

  return (
    <SidebarProvider>
      <main className="flex w-full flex-col md:flex-row">
        <Sidebar
          side={isMobile ? 'right' : 'left'}
          collapsible={isMobile ? 'offcanvas' : 'none'}
          variant={isMobile ? 'floating' : 'sidebar'}
          portalContainer={portalContainer}
        >
          <SidebarHeader>
            {logo ? <div className="mb-md h-xxxl mx-auto">{logo}</div> : null}
            <div className="desktop-only-block">
              <AvatarMenu
                avatarImageUrl={avatarImageUrl}
                avatarFallback={avatarFallback}
                title={avatarTitle}
                subTitle={avatarSubTitle}
              >
                {AvatarMenuContent}
              </AvatarMenu>
            </div>
          </SidebarHeader>
          {sidebarContent ? (
            <>
              <SidebarSeparator />
              <SidebarContent>{sidebarContent}</SidebarContent>
            </>
          ) : null}
          {sidebarFooter ? (
            <>
              {showSidebarFooterSeparator && <SidebarSeparator />}
              <SidebarFooter>{sidebarFooter}</SidebarFooter>
            </>
          ) : null}
        </Sidebar>
        <Topbar className="mobile-only-flex">
          {logo ? (
            <TopbarLeft>
              <div className="h-xl">{logo}</div>
            </TopbarLeft>
          ) : null}
          <TopbarRight>
            <div className="gap-md flex flex-row items-center">
              <PopoverAvatarMenu
                avatarImageUrl={avatarImageUrl}
                avatarFallback={avatarFallback}
                title={avatarTitle}
                subTitle={avatarSubTitle}
                className="max-w-[350px]"
                portalContainer={portalContainer}
              >
                {AvatarMenuContent}
              </PopoverAvatarMenu>
              <div>
                <SidebarTrigger />
              </div>
            </div>
          </TopbarRight>
        </Topbar>
        <div className={cn('flex flex-1 justify-stretch overflow-y-auto', className)}>{children}</div>
      </main>
    </SidebarProvider>
  );
};
AuthenticatedLayout.displayName = 'AuthenticatedLayout';
