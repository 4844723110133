'use client';

import * as React from 'react';
import { Check } from 'lucide-react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '@utils';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'border-primary-border focusable size-md peer shrink-0 border',
      'hover:border-primary data-[state=checked]:hover:border-primary-background-hover data-[state=checked]:hover:bg-primary-background-hover',
      'disabled:border-primary-disabled data-[state=checked]:disabled:bg-primary-background-disabled disabled:cursor-not-allowed',
      'data-[state=checked]:bg-primary-background data-[state=checked]:text-background data-[state=checked]:border-0',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
      <Check className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
