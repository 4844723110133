import * as React from 'react';

import { cn } from '@utils';
import Link from 'next/link';

type LinkProps = React.ComponentPropsWithoutRef<typeof Link> & { isExternal?: boolean };

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('bg-card-background p-lg border-card-border gap-md flex flex-col rounded-sm border', className)}
    {...props}
  />
));
Card.displayName = 'Card';

const CardLink = React.forwardRef<HTMLAnchorElement, LinkProps & { isExternal?: boolean; className?: string }>(
  ({ isExternal, className, ...props }, ref) => {
    const linkProps = isExternal ? { ...props, target: '_blank', rel: 'noopener noreferrer' } : props;
    return (
      <Link
        ref={ref}
        className={cn(
          'bg-card-background p-lg border-card-border gap-md focusable hover:bg-card-hover flex flex-col border',
          className,
        )}
        {...linkProps}
      />
    );
  },
);
CardLink.displayName = 'CardLink';

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('flex flex-col', className)} {...props} />,
);
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h3 ref={ref} className={cn('leading-xl !m-0 text-xl font-semibold tracking-normal', className)} {...props} />
  ),
);
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => <p ref={ref} className={cn('text-subtext', className)} {...props} />,
);
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={className} {...props} />,
);
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('mt-sm flex items-center', className)} {...props} />,
);
CardFooter.displayName = 'CardFooter';

export { Card, CardLink, CardHeader, CardFooter, CardTitle, CardDescription, CardContent };
