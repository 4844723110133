'use client';

import { type DialogProps } from '@radix-ui/react-dialog';
import { Command as CommandPrimitive } from 'cmdk';
import { Search, X } from 'lucide-react';
import * as React from 'react';

import { cn } from '@utils';
import { Button } from '../button/button';
import { Dialog, DialogContent } from '../dialog/dialog';

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(
      'text-foreground bg-background rounded-xxs gap-xxs relative flex h-full w-full flex-col overflow-visible',
      className,
    )}
    {...props}
  />
));
Command.displayName = CommandPrimitive.displayName;

type CommandDialogProps = DialogProps;

const CommandDialog = ({ children, ...props }: CommandDialogProps): React.JSX.Element => {
  return (
    <Dialog {...props}>
      <DialogContent className="overflow-hidden shadow-lg">
        <Command className="[&_[cmdk-group-heading]]:text-primary [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5">
          {children}
        </Command>
      </DialogContent>
    </Dialog>
  );
};

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    onCrossClicked?: () => void;
    showCross?: boolean;
    disableFocusStyle?: boolean;
  }
>(({ className, ...props }, ref) => {
  const { onCrossClicked, showCross = false, disableFocusStyle = false, ...commandProps } = props;
  return (
    <div
      className={cn(
        'border-primary-border flex h-full items-center overflow-visible rounded-sm border bg-transparent pl-3',
        'hover:border-primary-border-hover',
        'disabled:text-primary-disabled disabled:placeholder:text-primary-disabled disabled:border-primary-border-disabled disabled:cursor-not-allowed',
        !disableFocusStyle
          ? 'focus-within:ring-ring ring-offset-background focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2'
          : '',
      )}
      cmdk-input-wrapper=""
    >
      <Search className="mr-xs size-md shrink-0" />
      <CommandPrimitive.Input
        ref={ref}
        className={cn(
          'placeholder:text-subtext flex h-[40px] w-full bg-transparent py-3 text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        {...commandProps}
      />
      {props.onCrossClicked && showCross && <Button variant="link" Icon={X} onClick={props.onCrossClicked} />}
    </div>
  );
});

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn(
      'bg-background p-xxs z-40 max-h-[336px] min-h-[56px] overflow-y-auto overflow-x-hidden',
      'border-primary-border rounded-sm border',
      className,
    )}
    {...props}
  />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className="flex min-h-[40px] items-center justify-center text-center text-sm"
    {...props}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      'text-foreground',
      '[&_[cmdk-group-heading]]:text-foreground [&_[cmdk-group-heading]]:text-md overflow-hidden [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-2 [&_[cmdk-group-heading]]:font-medium',
      '[&_[cmdk-group-items]]:gap-xxs [&_[cmdk-group-items]]:flex [&_[cmdk-group-items]]:flex-col',
      className,
    )}
    {...props}
  />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator ref={ref} className={cn('bg-foreground h-px', className)} {...props} />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      'p-xs bg-background leading-sm relative flex cursor-pointer select-none items-center rounded-sm text-sm outline-none',
      'aria-selected:bg-secondary-background aria-selected:text-foreground',
      'data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50',
      className,
    )}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>): React.JSX.Element => {
  return <span className={cn('text-grey-400 ml-auto text-xs tracking-widest', className)} {...props} />;
};
CommandShortcut.displayName = 'CommandShortcut';

export {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
};
