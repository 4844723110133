'use client';
import React from 'react';
import { Home } from 'lucide-react';
import { cn } from '@utils';
import { Link } from '../link/link';
import { BackButton } from '../back-button/back-button';

export function Breadcrumb({ name, link = null }: { name: string; link?: string | null }): React.JSX.Element {
  if (link) {
    return (
      <Link href={link} className="truncate">
        {name}
      </Link>
    );
  }

  return <span className="text-primary-disabled truncate">{name}</span>;
}

export type BreadcrumbProps = {
  children: React.ReactNode;
  homeUrl?: string;
  className?: string;
};

export function Breadcrumbs({ children, homeUrl, className }: BreadcrumbProps): React.JSX.Element {
  const HomeIcon = (
    <div className="size-md">
      <Home className="size-md" />
    </div>
  );
  return (
    <div className={cn('gap-xxs flex max-w-fit flex-row items-center items-stretch', className)}>
      <BackButton className="px-xxxs py-xxs flex-shrink" />
      <h3 className="border-primary-border-disabled breadcrumb px-xs gap-xxs flex flex-1 items-center overflow-x-hidden rounded-sm border !font-normal uppercase">
        {homeUrl ? (
          <Link href={homeUrl} className="hover:bg-secondary-background-transparent size-md">
            {HomeIcon}
          </Link>
        ) : (
          HomeIcon
        )}
        {React.Children.map(children, (child) => (
          <>/{child}</>
        ))}
      </h3>
    </div>
  );
}
