import * as React from 'react';
import { cn } from '@utils';

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        'focusable text-md flex h-10 min-h-[80px] w-full border px-3 py-2',
        'disabled:text-primary-disabled disabled:placeholder:text-primary-disabled disabled:border-primary-border-disabled disabled:cursor-not-allowed',
        'hover:border-primary-border-hover',
        props['aria-invalid']
          ? 'bg-error-background border-error text-error placeholder:text-error-secondary disabled:placeholder:text-error-tertiary'
          : 'bg-background border-primary-border placeholder:text-subtext text-primary',
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
