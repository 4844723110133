'use client';

import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import * as SeparatorPrimitive from '@radix-ui/react-separator';

import { cn } from '@utils';

const separatorVariants = cva('shrink-0', {
  variants: {
    variant: {
      primary: 'bg-primary-separator',
      secondary: 'bg-secondary-separator',
    },
    orientation: {
      horizontal: 'h-[1px] w-full',
      vertical: 'h-full w-[1px]',
    },
  },
  defaultVariants: {
    variant: 'secondary',
    orientation: 'horizontal',
  },
});

const SeparatorWithoutLabel = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> & VariantProps<typeof separatorVariants>
>(({ className, children, orientation = 'horizontal', variant, decorative = true, ...props }, ref) => (
  <SeparatorPrimitive.Root
    ref={ref}
    decorative={decorative}
    orientation={orientation}
    className={cn(separatorVariants({ variant, orientation }), className)}
    {...props}
  />
));
SeparatorWithoutLabel.displayName = 'Separator without label';

const SeparatorWithLabel = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> &
    VariantProps<typeof separatorVariants> & { children: React.ReactNode }
>(({ className, children, orientation = 'horizontal', variant = 'secondary', decorative = true, ...props }, ref) => (
  <div
    className={cn(
      'flex items-center',
      orientation === 'horizontal' ? 'w-full flex-row' : 'h-full flex-col',
      variant === 'secondary' ? 'text-secondary-separator' : 'text-primary-separator',
      className,
    )}
    {...props}
  >
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(separatorVariants({ variant, orientation }), 'flex-1')}
    />
    <div className="flex-shrink">{children}</div>
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(separatorVariants({ variant, orientation }), 'flex-1')}
    />
  </div>
));
SeparatorWithLabel.displayName = 'Separator with label';

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> &
    VariantProps<typeof separatorVariants> & { children?: React.ReactNode }
>(({ children, ...props }, ref) =>
  children ? (
    <SeparatorWithLabel ref={ref} {...props}>
      {children}
    </SeparatorWithLabel>
  ) : (
    <SeparatorWithoutLabel ref={ref} {...props} />
  ),
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
