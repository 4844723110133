'use client';

import React, { useCallback, useRef } from 'react';
import { ConfirmationProps, Confirmation, ConfirmationRef } from './confirmation';

function useDynamicConfirm(props: ConfirmationProps): {
  confirm: () => void | undefined;
  confirmationModal: React.JSX.Element;
  cancelConfirm: () => void | undefined;
} {
  const confirmationRef = useRef<ConfirmationRef | null>(null);
  const confirm = useCallback(() => confirmationRef.current?.open(), []);
  const cancelConfirm = useCallback(() => confirmationRef.current?.close(), []);
  return {
    confirm,
    cancelConfirm,
    confirmationModal: <Confirmation ref={confirmationRef} {...props} />,
  };
}

export { useDynamicConfirm };
