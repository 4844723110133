import React from 'react';
import { cn } from '@utils';

export type TopbarLeftProps = {
  className?: string;
  children?: React.ReactNode;
};

export const TopbarLeft = ({ className, children }: TopbarLeftProps): JSX.Element => {
  return (
    <div data-topbar="left" className={cn('flex flex-1 flex-row justify-start', className)}>
      {children}
    </div>
  );
};

export type TopbarRightProps = {
  className?: string;
  children?: React.ReactNode;
};

export const TopbarRight = ({ className, children }: TopbarRightProps): JSX.Element => {
  return (
    <div data-topbar="right" className={cn('flex flex-1 flex-row justify-end', className)}>
      {children}
    </div>
  );
};

export type TopbarProps = {
  className?: string;
  children?: React.ReactNode;
};

export const Topbar = ({ className, children }: TopbarProps): JSX.Element => {
  return (
    <nav
      data-topbar="topbar"
      className={cn('py-md px-md sm:px-xl md:px-xxl text-foreground sticky top-0 flex w-full items-center', className)}
    >
      {children}
    </nav>
  );
};
Topbar.displayName = 'Topbar';
