'use client';
import NextLink from 'next/link';
import React from 'react';
import { cn } from '@utils';

export type LinkProps = React.ComponentPropsWithoutRef<typeof NextLink> & { isExternal?: boolean };

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, children, isExternal = false, ...props }, ref) => {
    const linkProps = isExternal ? { ...props, target: '_blank', rel: 'noopener noreferrer' } : props;
    return (
      <NextLink ref={ref} className={cn('focusable hover:underline', className)} {...linkProps}>
        {children}
      </NextLink>
    );
  },
);
Link.displayName = 'Link';
