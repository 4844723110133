import { NextFontWithVariable } from 'next/dist/compiled/@next/font';
import { Lato, Kode_Mono } from 'next/font/google';

export * from './persist';

export const latoBody = Lato({
  weight: ['100', '300', '400', '700', '900'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-body',
});
export const kodeMonoHighlight = Kode_Mono({
  weight: ['400', '700'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-highlight',
});

/**
 * Those values must match the `html[data-theme="{value}"]` in ./styles/themes/*.css
 */
export enum Theme {
  DARK = 'dark',
}

export const defaultTheme = Theme.DARK;

export type ThemeDescription = {
  name: string;
  fontBody: NextFontWithVariable; // font with variable --font-body
  fontHighlight: NextFontWithVariable; // font with variable --font-highlight
};

export const themeDescriptions: Record<Theme, ThemeDescription> = {
  [Theme.DARK]: {
    name: 'Dark theme',
    fontBody: latoBody,
    fontHighlight: kodeMonoHighlight,
  },
};
