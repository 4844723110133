'use client';
import { OnChangeFn, PaginationState } from '@tanstack/react-table';
import { useState } from 'react';

export const DEFAULT_PAGE_SIZE = 25;

type UsePaginationResponse = {
  onPaginationChange: OnChangeFn<PaginationState>;
  paginationState: PaginationState;
};
export function usePagination(pageSize = DEFAULT_PAGE_SIZE): UsePaginationResponse {
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize,
    pageIndex: 0,
  });

  return {
    paginationState: pagination,
    onPaginationChange: setPagination,
  };
}
