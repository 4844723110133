import * as React from 'react';

import { cn } from '@utils';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        'focusable text-md block h-10 w-full border px-3 py-2',
        'file:text-primary file:text-md file:border-0 file:bg-transparent file:font-medium',
        'disabled:text-primary-disabled disabled:placeholder:text-primary-disabled disabled:border-primary-border-disabled disabled:cursor-not-allowed',
        'hover:border-primary-border-hover',
        props['aria-invalid']
          ? 'bg-error-background border-error text-error placeholder:text-error-secondary disabled:placeholder:text-error-tertiary'
          : 'bg-background border-primary-border placeholder:text-subtext text-primary',
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = 'Input';

export { Input };
