import * as React from 'react';
import { CircularLoader } from '../circular-loader/circular-loader';

type Props = {
  progress: number;
};

export function CircularProgress({ progress }: Props): React.JSX.Element {
  const value = Math.min(progress, 100);

  return (
    <div className="relative h-fit w-fit">
      <CircularLoader value={100} className="text-secondary-background-transparent size-[256px]" />
      {value > 0 && <CircularLoader value={value} className="text-accent absolute left-0 top-0 size-[256px]" />}
    </div>
  );
}
