'use client';
import { CheckIcon, ChevronsUpDown } from 'lucide-react';

import * as React from 'react';

import * as RPNInput from 'react-phone-number-input';

import flags from 'react-phone-number-input/flags';

import { cn } from '@utils';
import { Button } from '../button/button';
import { Input, InputProps } from '../input/input';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '../command/command';
import { Popover, PopoverContent, PopoverTrigger } from '../popover/popover';

import { ScrollArea } from '../scroll-area/scroll-area';

type PhoneInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> &
  Omit<RPNInput.Props<typeof RPNInput.default>, 'onChange'> & {
    onChange?: (value: RPNInput.Value) => void;
    countrySelectPlaceholder: string;
    countrySelectEmptyState: React.ReactNode;
  };

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> = React.forwardRef<
  React.ElementRef<typeof RPNInput.default>,
  PhoneInputProps
>(({ className, onChange, countrySelectPlaceholder, countrySelectEmptyState, ...props }, ref) => {
  return (
    <RPNInput.default
      ref={ref}
      className={cn(
        'ring-offset-background flex rounded-sm border',
        'focus-within:ring-ring focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2',
        'hover:border-primary-border-hover',
        'disabled:placeholder:text-primary-disabled disabled:border-primary-border-disabled disabled:[&>button]:after:bg-primary-border-disabled disabled:cursor-not-allowed disabled:[&>button]:cursor-not-allowed',
        props['aria-invalid']
          ? '[&>button]:bg-error-background bg-error-background border-error text-error placeholder:text-error-secondary disabled:placeholder:text-error-tertiary [&>button]:after:bg-error'
          : 'bg-background border-primary-border placeholder:text-subtext text-primary disabled:text-primary-disabled disabled:[&>button]:text-primary-disabled',
        className,
      )}
      flagComponent={FlagComponent}
      countrySelectComponent={(csProps) => (
        <CountrySelect
          countrySelectPlaceholder={countrySelectPlaceholder}
          countrySelectEmptyState={countrySelectEmptyState}
          {...csProps}
        />
      )}
      inputComponent={InputComponent}
      /**
       * Handles the onChange event.
       *
       * react-phone-number-input might trigger the onChange event as undefined
       * when a valid phone number is not entered. To prevent this,
       * the value is coerced to an empty string.
       *
       * @param {E164Number | undefined}
        value - The entered value
       */
      onChange={(value) => onChange?.(value || ('' as RPNInput.Value))}
      {...props}
    />
  );
});
PhoneInput.displayName = 'PhoneInput';

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => (
  <Input
    className={cn('h-10 flex-1 border-none focus-visible:ring-0 focus-visible:ring-offset-0', className)}
    {...props}
    ref={ref}
  />
));
InputComponent.displayName = 'InputComponent';

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  onChange: (value: RPNInput.Country) => void;
  options: CountrySelectOption[];
  countrySelectPlaceholder: string;
  countrySelectEmptyState: React.ReactNode;
};

const CountrySelect = ({
  disabled,
  value,
  onChange,
  countrySelectPlaceholder,
  countrySelectEmptyState,
  options,
}: CountrySelectProps): JSX.Element => {
  const handleSelect = React.useCallback(
    (country: RPNInput.Country) => {
      onChange(country);
    },
    [onChange],
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant="quaternary"
          className={cn(
            'relative gap-1 border-none text-inherit focus-visible:ring-0 focus-visible:ring-offset-0',
            'after:bg-primary-border after:top-1/6 after:bottom-1/6 relative after:absolute after:right-0 after:h-2/3 after:w-[1px] after:content-[""]',
          )}
          disabled={disabled}
          Icon={(props) => <FlagComponent country={value} countryName={value} {...props} />}
          RightIcon={ChevronsUpDown}
        />
      </PopoverTrigger>
      <PopoverContent className="border-primary-border p-none w-[300px] rounded-sm border-none">
        <Command>
          <CommandList className="bg-background text-foreground">
            <ScrollArea className="h-72">
              <CommandInput placeholder={countrySelectPlaceholder} />
              <CommandEmpty>{countrySelectEmptyState}</CommandEmpty>
              <CommandGroup>
                {options
                  .filter((option) => option.value)
                  .map((option) => (
                    <CommandItem
                      className="aria-selected:bg-secondary-background aria-selected:text-foreground gap-2 rounded-sm text-sm"
                      key={option.value}
                      onSelect={() => handleSelect(option.value)}
                    >
                      <FlagComponent country={option.value} countryName={option.label} />
                      <span className="flex-1">{option.label}</span>
                      {option.value && <span>+{RPNInput.getCountryCallingCode(option.value)}</span>}
                      <CheckIcon className={cn('ml-auto h-4 w-4', option.value === value ? '' : 'invisible')} />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps): JSX.Element => {
  const Flag = flags[country];

  return (
    <span className="flex h-[13.3px] w-[20px] rounded-sm">
      {Flag ? (
        <Flag title={countryName} />
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 60">
          <path className="fill-secondary" d="M0 0h90v60H0z" />
        </svg>
      )}
    </span>
  );
};
FlagComponent.displayName = 'FlagComponent';

export { PhoneInput };
