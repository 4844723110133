'use client';
import { CalendarIcon } from 'lucide-react';
import React, { useState } from 'react';
import { Popover, PopoverTrigger, PopoverContent } from '@radix-ui/react-popover';
import { FieldValues } from 'react-hook-form';
import { DateFormatter } from 'react-day-picker';
import { cn, formatToHumanReadable, FORMAT_PATTERN, getMatchingLocale } from '@utils';
import { Calendar, CalendarProps } from '../calendar/calendar';
import { Button } from '../button/button';

// see: https://react-day-picker.js.org/api/types/Formatters
type FormatType = 'formatMonthCaption';

type Props = Omit<CalendarProps, 'locale'> & {
  field: FieldValues;
  formatters?: Record<FormatType, DateFormatter>;
  fromYear: number;
  toYear: number;
  locale: string;
  placeholder: string;
  buttonClassName?: string;
  disabled?: boolean;
  ['aria-invalid']?: boolean;
};

export function DatePicker({ disabled, ...props }: Props): React.JSX.Element {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const { field, formatters, fromYear, locale, placeholder, toYear, buttonClassName, ...calendarProps } = props;

  return (
    <Popover open={datePickerOpen} onOpenChange={setDatePickerOpen}>
      <PopoverTrigger asChild>
        <Button
          data-testid="register-birth-date"
          variant="tertiary"
          className={cn(
            '!text-md w-[292px] max-w-none justify-between font-normal normal-case',
            props['aria-invalid']
              ? 'bg-error-background border-error text-error'
              : disabled
                ? 'text-primary-disabled border-primary-border-disabled'
                : '',
            buttonClassName,
          )}
          disabled={disabled}
          RightIcon={CalendarIcon}
        >
          {field.value ? (
            formatToHumanReadable(field.value, locale, FORMAT_PATTERN.DATE_FORMAT)
          ) : (
            <span
              className={
                props['aria-invalid']
                  ? disabled
                    ? 'text-error-ternary'
                    : 'text-error-secondary'
                  : disabled
                    ? 'text-primary-disabled'
                    : 'text-subtext'
              }
            >
              {placeholder}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="bg-background my-xxs font-body z-50 w-auto p-0 text-sm">
        <Calendar
          initialFocus
          classNames={{
            root: 'border-1 border-primary-border rounded-sm',
            caption_dropdowns: 'flex',
            caption_label: 'hidden',
            vhidden: 'hidden',
          }}
          captionLayout="dropdown-buttons"
          fixedWeeks
          fromYear={fromYear}
          formatters={formatters}
          locale={getMatchingLocale(locale)}
          mode="single"
          onDayClick={() => setDatePickerOpen(false)}
          onSelect={field.onChange}
          selected={field.value}
          showOutsideDays
          toYear={toYear}
          defaultMonth={field.value}
          {...calendarProps}
        />
      </PopoverContent>
    </Popover>
  );
}
