'use client';
import * as React from 'react';
import { Button } from '../button/button';
import { Card, CardContent, CardFooter, CardHeader } from '../card/card';

type ErrorAction = {
  title: string;
  url: string;
};

type ErrorProps = {
  title: string;
  code: string | number;
  message: string;
  action?: ErrorAction;
};

export function ErrorPage(props: ErrorProps): React.JSX.Element {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Card className="py-xxl gap-none w-full border-none">
        <CardHeader className="leading-lg text-center text-xl" data-testid="error-page-header">
          {props.title}
        </CardHeader>
        <CardContent>
          <h1
            className="text-center text-[6.25rem] font-black leading-[6.25rem] sm:text-[10rem] sm:leading-[10rem]"
            data-testid="error-page-code"
          >
            {props.code}
          </h1>
          <div className="heading-5 sm:heading-3 text-center" data-testid="error-page-message">
            {props.message}
          </div>
        </CardContent>
        {props.action && (
          <CardFooter className="mt-xxl flex items-center justify-center">
            <Button variant="secondary" href={props.action.url} data-testid="error-page-action-url">
              <span data-testid="error-page-action-title">{props.action.title}</span>
            </Button>
          </CardFooter>
        )}
      </Card>
    </div>
  );
}
