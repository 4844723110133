'use client';
import { Theme, themeDescriptions } from '../../themes';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '..';

const availableThemes = [...Object.values(Theme)];

export type ThemeSwitcherProps = {
  className?: string;
  theme: Theme;
  onChange: (newTheme: Theme) => void;
};

export function ThemeSwitcher({ className, theme, onChange }: ThemeSwitcherProps): JSX.Element {
  return (
    <Select defaultValue={theme} onValueChange={onChange}>
      <SelectTrigger className={className}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {availableThemes.map((themeItem) => (
          <SelectItem value={themeItem} key={themeItem}>
            {themeDescriptions[themeItem].name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
