import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

import { type Table } from '@tanstack/react-table';
import { Button } from '../button/button';

interface TablePaginationProps<TData> {
  table: Table<TData>;
  nextLabel?: string;
  previousLabel?: string;
}

export function TablePagination<TData>({
  table,
  nextLabel = 'Next',
  previousLabel = 'Previous',
}: TablePaginationProps<TData>): React.JSX.Element {
  return (
    <div className="mt-xl gap-md flex items-center justify-end">
      <Button
        aria-label="Go to previous page"
        variant="quaternary"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
        Icon={ChevronLeftIcon}
      >
        {previousLabel}
      </Button>
      <span className="leading-xs md:leading-sm text-xs font-bold md:text-sm">{`${table.getState().pagination.pageIndex + 1}/${table.getPageCount()}`}</span>
      <Button
        aria-label="Go to next page"
        variant="quaternary"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
        RightIcon={ChevronRightIcon}
      >
        {nextLabel}
      </Button>
    </div>
  );
}
