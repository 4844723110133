'use client';

import * as React from 'react';

import { cn } from '@utils';

const VIEWPORT_SIZE = 48;
const RADIUS = 16;
const STROKE_WIDTH = 4;

const MAX_LENGTH = 2 * Math.PI * RADIUS;

export type CircularLoaderProps = {
  value?: number;
};

export const CircularLoader = React.forwardRef<
  SVGSVGElement,
  React.ComponentPropsWithoutRef<'svg'> & CircularLoaderProps
>(({ className, value, ...props }, ref) => {
  /*
    If the value is undefined, then the component becomes a spinner
    (the animations are defined in tailwind.config.js)

    If the value is lower than 0.001, then it's replaced with 0.001 otherwise the loader
    would be invisible: in that case we display a dot
  */
  const length = value === undefined ? 0 : (MAX_LENGTH * (100 - Math.max(0.001, value))) / 100;
  return (
    <svg
      viewBox={`-${VIEWPORT_SIZE / 2} -${VIEWPORT_SIZE / 2} ${VIEWPORT_SIZE} ${VIEWPORT_SIZE}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('-rotate-90', className)}
      ref={ref}
      {...props}
    >
      <g className={value === undefined ? 'animate-rotate' : ''}>
        <circle
          r={RADIUS}
          cx="0"
          cy="0"
          stroke="currentColor"
          strokeWidth={`${STROKE_WIDTH}px`}
          strokeLinecap="round"
          strokeDashoffset={`${length}px`}
          fill="transparent"
          strokeDasharray={`${MAX_LENGTH}px`}
          className={value === undefined ? 'animate-spinner' : ''}
        />
      </g>
    </svg>
  );
});
CircularLoader.displayName = 'Circular loader';
