import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@utils';

const tagVariants = cva('px-sm py-xs rounded text-sm font-normal inline-flex items-center', {
  variants: {
    variant: {
      default: 'bg-grey-100 text-black',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface TagProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof tagVariants> {}

function Tag({ className, variant, ...props }: TagProps): React.JSX.Element {
  return <div className={cn(tagVariants({ variant }), className)} {...props} />;
}

export { Tag, tagVariants };
