'use client';

import { ColumnSort, OnChangeFn, SortingState } from '@tanstack/react-table';
import { useState } from 'react';

type UseSortingResponse<T extends ColumnSort['id']> = {
  sortingState: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
  sortBy?: T;
  sortOrder: 'asc' | 'desc';
};
export function useSorting<T extends ColumnSort['id']>(initialState?: SortingState): UseSortingResponse<T> {
  const [state, setState] = useState<SortingState>(initialState || []);

  return {
    sortingState: state,
    onSortingChange: setState,
    sortBy: state[0]?.id as T,
    sortOrder: state[0]?.desc ? 'desc' : 'asc',
  };
}
