'use client';

import { ComponentProps, ComponentPropsWithoutRef, ElementRef, HTMLAttributes, forwardRef } from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

import { cn } from '@utils';
import { DrawerContextProvider, useDrawerContext } from './drawer-context';

const Drawer = ({ shouldScaleBackground = true, ...props }: ComponentProps<typeof DrawerPrimitive.Root>): any => (
  <DrawerContextProvider direction={props.direction}>
    <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
  </DrawerContextProvider>
);
Drawer.displayName = 'Drawer';

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = forwardRef<
  ElementRef<typeof DrawerPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay ref={ref} className={cn('fixed inset-0 bg-black !opacity-80', className)} {...props} />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

const DrawerLine = (): JSX.Element => <div className="bg-foreground my-md h-1 w-[60px] rounded-full" />;

const DrawerContent = forwardRef<
  ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, autoFocus = true, ...props }, ref) => {
  const { direction } = useDrawerContext();
  return (
    <DrawerPortal>
      <DrawerOverlay className="z-50" />
      <DrawerPrimitive.Content
        autoFocus={autoFocus}
        ref={ref}
        className={cn(
          'fixed z-50 flex flex-col items-center',
          direction === 'left' ? 'p-xl left-0 top-0 h-full rounded-r-lg' : '',
          direction === 'right' ? 'p-xl right-0 top-0 h-full rounded-l-lg' : '',
          direction === 'top' ? 'px-lg pt-lg left-0 top-0 w-full rounded-b-lg' : '',
          direction === 'bottom' ? 'px-lg pb-lg bottom-0 left-0 w-full rounded-t-lg' : '',
          'bg-secondary-background-disabled border-secondary-background border',
          className,
        )}
        {...props}
      >
        {direction === 'bottom' && <DrawerLine />}
        <div
          className={cn(
            'text-secondary body-3 mx-auto flex h-full w-full flex-col self-stretch',
            direction === 'left' || direction === 'right' ? 'max-w-[534px]' : 'max-h-[560px] max-w-[400px]',
            className,
          )}
        >
          {children}
        </div>
        {direction === 'top' && <DrawerLine />}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  );
});
DrawerContent.displayName = 'DrawerContent';

const DrawerHeader = ({ className, ...props }: HTMLAttributes<HTMLDivElement>): React.JSX.Element => {
  return <div className={cn('mb-md', className)} {...props} />;
};
DrawerHeader.displayName = 'DrawerHeader';

const DrawerFooter = ({ className, ...props }: HTMLAttributes<HTMLDivElement>): React.JSX.Element => (
  <div
    className={cn('space-y-xs flex flex-col', '[&>button]:w-full', 'pt-md mt-auto justify-stretch', className)}
    {...props}
  />
);
DrawerFooter.displayName = 'DrawerFooter';

const DrawerTitle = forwardRef<
  ElementRef<typeof DrawerPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title ref={ref} className={cn('body-1 text-primary font-semibold', className)} {...props} />
));
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription = forwardRef<
  ElementRef<typeof DrawerPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description ref={ref} className={cn('text-secondary body-2', className)} {...props} />
));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

export {
  Drawer,
  DrawerPortal,
  DrawerOverlay,
  DrawerTrigger,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerDescription,
};
