'use client';

import React from 'react';
import { cn } from '@utils';
import { cva } from 'class-variance-authority';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../accordion/accordion';

const stepperVariant = cva(
  cn('px-xs py-xxs flex w-xl flex-wrap items-center justify-center -ml-[1px] -mt-[1px] rounded-tl-inherit'),
  {
    variants: {
      variant: {
        primary: 'bg-primary-background text-background',
        success: 'bg-success-primary text-white',
        warning: 'bg-warning-primary text-white',
        error: 'bg-error-primary text-white',
        disabled: 'bg-primary-background-disabled text-white !opacity-20',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

export type Step = {
  children?: React.ReactNode;
  collapseStatus?: 'opened' | 'closed';
  description?: React.ReactNode;
  leftElement?: React.ReactNode;
  title: string;
  variant?: 'warning' | 'success' | 'primary' | 'disabled';
};

type Props = {
  steps: Step[];
};
export function Stepper({ steps }: Props): React.JSX.Element {
  const defaultOpenedIds = steps.reduce<string[]>((ids, step, index) => {
    if (step.collapseStatus !== 'closed') {
      ids.push(`step_${index}`);
    }
    return ids;
  }, []);
  return (
    <Accordion type="multiple" defaultValue={defaultOpenedIds}>
      {steps.map((step, index): React.ReactElement<typeof AccordionItem> => {
        const notCollapsible = step.collapseStatus === undefined;
        const variant = step.variant || 'primary';
        return (
          <AccordionItem
            key={`step_${index}`}
            disabled={notCollapsible}
            className="border-secondary-background bg-secondary-background-transparent !gap-none rounded-sm border"
            value={`step_${index}`}
          >
            <div className="min-h-xl rounded-inherit grid grid-cols-[2rem_auto]">
              <div className={cn(stepperVariant({ variant }), `${!step.children && 'rounded-bl-inherit'}`)}>
                {step.leftElement ? step.leftElement : <span>{`${index + 1}`}</span>}
              </div>
              <div className={`px-xs py-xxs flex flex-col ${variant === 'disabled' ? 'opacity-20' : ''}`}>
                {step.description ? (
                  <>
                    <AccordionTrigger
                      hideChevron={notCollapsible}
                      className={notCollapsible ? 'hover:no-underline' : ''}
                    >
                      {step.title}
                    </AccordionTrigger>
                    <AccordionContent className="text-subtext text-base">{step.description}</AccordionContent>
                  </>
                ) : (
                  step.title
                )}
              </div>
            </div>
            {step.children && <div className="bg-background p-lg rounded-b-sm">{step.children}</div>}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}
