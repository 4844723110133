import React from 'react';
import { cn } from '@utils';

const octagonPath = 'M 0 50 L 14.64 85.36 L 50 100 L 85.36 85.36 L 100 50 L 85.36 14.64 L 50 0 L 14.64 14.64 L 0 50';

export type FancyBadgeProps = {
  children: React.ReactNode;
  className?: string;
  variant?: 'full' | 'hollow';
};

export function FancyBadge({ children, className, variant = 'full' }: FancyBadgeProps): React.JSX.Element {
  return (
    <div className={cn('relative', className)}>
      <svg
        className="absolute left-0 top-0 h-full w-full"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stopColor="var(--accent-gradient-from)" />
            <stop offset="100%" stopColor="var(--accent-gradient-to)" />
          </linearGradient>
          <mask id="borderMask">
            <path d={octagonPath} stroke="white" fill="none" strokeWidth="2" />
          </mask>
        </defs>
        {variant === 'full' ? (
          <path d={octagonPath} stroke="var(--secondary-accent)" fill="url(#gradient)" />
        ) : (
          <>
            <path d={octagonPath} stroke="none" fill="var(--secondary-background-transparent)" />
            {/* svg cannot apply a gradient directly to a stroke, therefore we use the stroke as a mask */}
            <rect width="100" height="100" fill="url(#gradient)" mask="url(#borderMask)" />
          </>
        )}
      </svg>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">{children}</div>
    </div>
  );
}
FancyBadge.displayName = 'Fancy badge';
