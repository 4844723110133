'use client';
import * as React from 'react';
import { ReactElement, Ref, useEffect } from 'react';
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from '../command/command';
import { CircularLoader } from '../circular-loader/circular-loader';

function useDebounce(value: string, delay: number): any {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return (): void => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export type SearchResult<T, Item = DefaultSearchItem> = {
  result: T;
  item: Item;
};

const DEFAULT_NUMBER_OF_CHARACTERS = 2;
const DELAY_BEFORE_SEARCH_IN_MS = 300;

export function Search<T extends ObjectWithId>(
  props: Omit<SearchProps<T, DefaultSearchItem>, 'ItemComponent'>,
): React.JSX.Element {
  return <CustomSearch<T, DefaultSearchItem> ItemComponent={DefaultSearchItem} {...props} />;
}

type ObjectWithId = { id: string };
type SearchProps<T extends ObjectWithId, Item> = {
  args?: Record<string, any>;
  delayBeforeSearchInMs?: number;
  ItemComponent: React.FC<{ item: Item }>;
  noResultsLabel?: React.ReactNode;
  numberOfCharactersBeforeSearch?: number;
  onSearch: (parameters: { searchQuery: string }) => Promise<SearchResult<T, Item>[]>;
  onSelect?: (value: T) => void;
  placeholder?: string;
  searchInputRef?: Ref<HTMLInputElement>;
};

export function CustomSearch<T extends ObjectWithId, Item>({
  args,
  delayBeforeSearchInMs = DELAY_BEFORE_SEARCH_IN_MS,
  ItemComponent,
  noResultsLabel,
  numberOfCharactersBeforeSearch = DEFAULT_NUMBER_OF_CHARACTERS,
  onSearch,
  onSelect,
  placeholder,
  searchInputRef,
}: SearchProps<T, Item>): React.JSX.Element {
  const [commandInput, setCommandInput] = React.useState<string>('');
  const [searchResults, setSearchResults] = React.useState<SearchResult<T, Item>[] | null>([]);
  const atLeastNCharactersTyped = commandInput !== '' && commandInput.length >= numberOfCharactersBeforeSearch;
  const debouncedSearch = useDebounce(commandInput, delayBeforeSearchInMs);

  const fetchSuggestions = React.useCallback(async (): Promise<void> => {
    const suggestions = await onSearch({ searchQuery: commandInput, ...args });
    setSearchResults(suggestions);
  }, [args, commandInput, onSearch]);

  useEffect(() => {
    setSearchResults(null);
    if (!atLeastNCharactersTyped) {
      return;
    }

    if (debouncedSearch) {
      fetchSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  function resetSearch(): void {
    setSearchResults(null);
    setCommandInput('');
  }

  useEffect(() => {
    const handleEscKeyOnPressed = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        event.preventDefault();
        resetSearch();
      }
    };
    window.addEventListener('keydown', handleEscKeyOnPressed);
    return (): void => {
      window.removeEventListener('keydown', handleEscKeyOnPressed);
    };
  }, []);

  return (
    <Command loop shouldFilter={false}>
      <CommandInput
        id="search-bar"
        ref={searchInputRef}
        placeholder={placeholder}
        value={commandInput}
        onValueChange={(value) => {
          setSearchResults(null);
          setCommandInput(value);
        }}
        onCrossClicked={resetSearch}
        showCross={commandInput?.length > 0}
      />
      <CommandList
        className={`absolute left-0 right-0 top-[48px] z-40 rounded-sm ${atLeastNCharactersTyped ? 'min-h-[56]' : 'hidden'}`}
      >
        {atLeastNCharactersTyped && (
          <>
            {!searchResults ? (
              <div className="flex items-center justify-center">
                <CircularLoader className="size-xxl" />
              </div>
            ) : debouncedSearch && searchResults.length !== 0 ? (
              searchResults.map((searchResult) => {
                return (
                  <CommandItem
                    key={searchResult.result.id}
                    value={searchResult.result.id}
                    onSelect={(): void => {
                      if (onSelect) {
                        onSelect(searchResult.result);
                      }
                    }}
                  >
                    <ItemComponent item={searchResult.item} />
                  </CommandItem>
                );
              })
            ) : (
              <CommandEmpty>{noResultsLabel}</CommandEmpty>
            )}
          </>
        )}
      </CommandList>
    </Command>
  );
}

export type DefaultSearchItem = {
  leftSide: ReactElement;
  rightSide: ReactElement;
};

export function DefaultSearchItem(props: { item: DefaultSearchItem }): React.JSX.Element {
  return (
    <div className="flex h-full w-full justify-between text-base">
      <div>{props.item.leftSide}</div>
      <div>{props.item.rightSide}</div>
    </div>
  );
}
