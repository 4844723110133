'use client';
import { cn } from '@utils';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../tooltip/tooltip';

export type TruncatedTextWithTooltipProps = React.HTMLAttributes<HTMLSpanElement> & { text: string };
const TruncatedTextWithTooltip = ({ className, text, ...props }: TruncatedTextWithTooltipProps): JSX.Element => {
  const ctnRef = useRef<HTMLDivElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const handleResize = (): void => {
      const spanWidth = spanRef.current?.offsetWidth || 0;
      const ctnWidth = ctnRef.current?.offsetWidth || 0;
      setIsTruncated(spanWidth > ctnWidth);
    };
    handleResize();
    window?.addEventListener('resize', handleResize);

    return (): void => window?.removeEventListener('resize', handleResize);
  }, [spanRef, ctnRef, text]);

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div ref={ctnRef} className="w-full overflow-hidden truncate">
            <span ref={spanRef} className={cn('truncate', className)} {...props}>
              {text}
            </span>
          </div>
        </TooltipTrigger>
        {isTruncated && <TooltipContent>{text}</TooltipContent>}
      </Tooltip>
    </TooltipProvider>
  );
};

export { TruncatedTextWithTooltip };
