'use client';

import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';

import { cn } from '@utils';
import { Label } from '../label/label';

export const ProgressBar = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
    label: string;
    currentValue: number;
    completedValue: number;
  }
>(({ className, label, currentValue, completedValue, ...props }, ref) => {
  const ceilCurrentValue = Math.min(currentValue, completedValue);
  const value = Math.round((ceilCurrentValue / completedValue) * 100);

  return (
    <div className="gap-sm flex flex-col self-stretch">
      <div className="gap-sm flex inline-flex items-center justify-start">
        <Label className="shrink grow basis-0 text-sm font-bold leading-4">{label}</Label>
        <p className="text-sm font-normal leading-4">{`(${ceilCurrentValue}/${completedValue})`}</p>
      </div>
      <ProgressPrimitive.Root
        ref={ref}
        className={cn('bg-secondary-separator relative h-1.5 w-full overflow-hidden rounded-full', className)}
        {...props}
      >
        <ProgressPrimitive.Indicator
          className={`h-full w-full flex-1 ${value === 100 ? 'bg-success' : 'bg-accent'} transition-all`}
          style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
        />
      </ProgressPrimitive.Root>
    </div>
  );
});
ProgressBar.displayName = ProgressPrimitive.Root.displayName;
