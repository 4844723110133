import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { LoaderCircle, LucideProps } from 'lucide-react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cn } from '@utils';
import Link from 'next/link';

const buttonVariants = cva(
  cn(
    'inline-flex items-center justify-center whitespace-nowrap font-bold font-body uppercase focusable transition-colors overflow-hidden h-fit w-fit leading-none',
    'disabled:pointer-events-none',
    'text-xs leading-xs md:text-sm md:leading-sm',
  ),
  {
    variants: {
      variant: {
        primary: cn(
          'bg-primary-background text-background',
          'hover:bg-primary-background-hover',
          'disabled:bg-primary-background-disabled',
        ),
        secondary: cn(
          'bg-secondary-background-transparent text-primary border border-secondary-border',
          'hover:border-primary',
          'disabled:text-primary-disabled disabled:bg-secondary-background-disabled disabled:border-secondary-border-disabled',
        ),
        tertiary: cn(
          'bg-tertiary-background text-primary border border-tertiary-border',
          'hover:border-primary',
          'disabled:text-primary-disabled disabled:bg-tertiary-background-disabled disabled:border-tertiary-border-disabled',
        ),
        quaternary: cn(
          'bg-transparent text-primary border border-transparent',
          'hover:border hover:border-primary',
          'disabled:text-primary-disabled',
        ),
        link: cn('bg-transparent text-primary', 'hover:underline', 'disabled:text-primary-disabled'),
        destructive: cn(
          'bg-error-background text-error border border-error-border',
          'hover:border-error',
          'disabled:text-error-disabled disabled:bg-error-background-disabled disabled:border-error-border-disabled',
        ),
        success: cn(
          'bg-success-background text-success border border-success-border',
          'hover:border-success',
          'disabled:text-succes-disabled disabled:bg-success-background-disabled disabled:border-success-border-disabled',
        ),
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

const ButtonContainer = React.forwardRef<HTMLElement, ButtonProps & { iconButton?: boolean }>(
  (
    { iconButton = false, loading, asChild, variant, className, disabled, children, href, isExternal, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : href ? Link : 'button';
    const extendedProps =
      href && isExternal
        ? {
            ...props,
            'target': '_blank',
            'rel': 'noopener noreferrer',
            'aria-disabled': disabled ? true : undefined,
          }
        : props;

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, className }),
          loading ? 'pointer-events-none select-none' : '',
          iconButton ? 'px-sm py-sm' : 'px-md py-sm',
        )}
        ref={ref as any}
        disabled={disabled}
        href={href as string}
        {...extendedProps}
      >
        {children}
      </Comp>
    );
  },
);
ButtonContainer.displayName = 'ButtonContainer';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  Icon?: React.FC<LucideProps>;
  RightIcon?: React.FC<LucideProps>;
  href?: string;
  isExternal?: boolean;
  download?: boolean;
  srOnly?: string;
}

const Button = React.forwardRef<HTMLElement, ButtonProps>(
  ({ Icon, RightIcon, loading = false, children, srOnly, ...props }, ref) => {
    if ((Icon || RightIcon) && children) {
      // Button with icon(s)/spinner next to label
      // The left icon can be replaced with a spinner only if there is no right icon
      const leftSpinner = loading && !RightIcon;
      return (
        <ButtonContainer loading={loading} ref={ref} {...props}>
          {Icon && leftSpinner ? <LoaderCircle className="mr-xs h-[16px] w-[16px] animate-spin" /> : null}
          {Icon && !leftSpinner ? <Icon className="mr-xs h-[16px] w-[16px]" /> : null}
          <Slottable>
            <span className="truncate">{children}</span>
          </Slottable>
          {RightIcon && loading ? <LoaderCircle className="ml-xs h-[16px] w-[16px] animate-spin" /> : null}
          {RightIcon && !loading ? <RightIcon className="ml-xs h-[16px] w-[16px]" /> : null}
        </ButtonContainer>
      );
    } else if (children) {
      // Button with label alone, or spinner over invisible label
      return (
        <ButtonContainer loading={loading} ref={ref} {...props} className={cn(props.className, 'relative')}>
          <Slottable>
            <span className={cn('truncate', loading ? 'invisible' : '')}>{children}</span>
          </Slottable>
          {loading ? (
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <LoaderCircle className="h-[16px] w-[16px] animate-spin" />
            </div>
          ) : null}
        </ButtonContainer>
      );
    } else if (Icon || RightIcon) {
      // Button with icon/spinner alone
      return (
        <ButtonContainer iconButton loading={loading} ref={ref} {...props}>
          {Icon ? <Icon className="h-[16px] w-[16px]" /> : null}
          {RightIcon ? <RightIcon className="h-[16px] w-[16px]" /> : null}
          {srOnly ? <span className="sr-only">{srOnly}</span> : null}
        </ButtonContainer>
      );
    } else {
      throw new Error('Empty button');
    }
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
