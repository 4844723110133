import * as React from 'react';

export function useTextReveal(text: string): string {
  const [displayedCharIndex, setDisplayedCharIndex] = React.useState(0);

  // Reset method
  React.useEffect(() => {
    setDisplayedCharIndex(0);
  }, [text]);

  // Reveal method
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (displayedCharIndex === text.length) {
        clearInterval(interval);
        return;
      }

      setDisplayedCharIndex((prev) => prev + 1);
    }, 100);
    return (): void => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, displayedCharIndex]);

  return displayedCharIndex === 0 ? '\u00A0' : text.substring(0, displayedCharIndex);
}
