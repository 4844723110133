'use client';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from '@utils';
import React from 'react';

const TooltipTriggerText = React.forwardRef<HTMLSpanElement, Omit<React.HTMLAttributes<HTMLSpanElement>, 'ref'>>(
  ({ className, children, ...props }, ref) => (
    <TooltipPrimitive.Trigger asChild>
      <span
        className={cn('focusable cursor-help underline decoration-dotted', className)}
        tabIndex={0}
        ref={ref}
        {...props}
      >
        {children}
      </span>
    </TooltipPrimitive.Trigger>
  ),
);

TooltipTriggerText.displayName = TooltipPrimitive.Trigger.displayName;

export { TooltipTriggerText };
